/* eslint-disable vtex/prefer-early-return */
import axios from 'axios'
import { SearchProvider } from '@faststore/sdk'
import { useSession } from 'src/sdk/session'
import { graphql } from 'gatsby'
import { BreadcrumbJsonLd } from 'gatsby-plugin-next-seo'
import React, { useEffect, useRef, useState } from 'react'
import { gql } from '@faststore/graphql-utils'
import ProductGallery from 'src/components/sections/ProductGallery'
import { useSearchParams } from 'src/hooks/useSearchParams'
import { applySearchState } from 'src/sdk/search/state'
import { mark } from 'src/sdk/tests/mark'
import type { Props } from 'src/hooks/useSearchParams'
import RenderCMS from 'src/components/RenderCMS'
import { isNotFoundError } from '@faststore/api'
import { execute } from 'src/server/index'
import '../styles/pages/product-listing-page.scss'
import '../styles/pages/departament.scss'
import { usePageViewEvent } from 'src/sdk/analytics/hooks/usePageViewEvent'
import useWindowDimensions from 'src/hooks/useWindowDimensions'
import productPerPage from 'src/utils/productPerPage'
import { FilterContextProvider } from 'src/contexts/FilterContext/filter-context'
import { useCampaignContext } from 'src/contexts/campaign-context'
import GatsbySeoCustom from 'src/components/GatsbySeoCustom'

import storeConfig from '../../store.config'

function Page(props: Props) {
  const { person } = useSession()
  const [didMount, setDidMount] = useState(false)
  const { sendPageViewEvent } = usePageViewEvent('Category', person)
  const { setCampaignPage } = useCampaignContext()

  setCampaignPage(false)

  const timerRef = useRef<NodeJS.Timeout | undefined>()

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }

    timerRef.current = setTimeout(() => {
      if (!didMount) {
        sendPageViewEvent()
        setDidMount(true)
      }
    }, 500)
  }, [person])

  useEffect(() => {
    window.insider_object = window.insider_object || {}
    window.insider_object.page = {
      type: 'Category',
      originalType: 'Category',
    }
  }, [])

  const {
    data: { site, cmsHome, allCmsPlp },
    serverData,
    location: { pathname },
  } = props

  const [titlePage, setTitlePage] = useState<string>('')

  useEffect(() => {
    if (serverData?.collection?.breadcrumbList?.itemListElement) {
      const titlePageColletion =
        serverData?.collection?.breadcrumbList?.itemListElement?.find(
          (element) =>
            element.item === window.location.pathname ||
            element.item === window.location.pathname.slice(0, -1)
        )

      if (titlePageColletion?.name) {
        setTitlePage(titlePageColletion.name)
      }
    }
  }, [serverData])

  const { locale } = useSession()
  const searchParams = useSearchParams(props)
  const defaultFacets = serverData?.collection?.meta?.selectedFacets
  const { width } = useWindowDimensions()
  const [isLifePage, setIsLifePage] = useState<boolean | undefined>(undefined)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsLifePage(window?.location?.pathname.includes('life'))
    }
  }, [])

  const ITEM_PER_PAGE_VERIFIED = productPerPage(width)

  if (!serverData) {
    return null
  }

  const { collection } = serverData

  const { base } = searchParams
  const title = collection?.seo.title ?? site?.siteMetadata?.title ?? ''
  const description =
    collection?.seo.description ?? site?.siteMetadata?.description ?? ''

  const { storeUrl } = storeConfig
  const canonical = `${storeUrl}${pathname}`

  const { edges } = allCmsPlp

  const [sections] = edges?.map((e) => e?.node?.sections)

  return (
    <SearchProvider
      onChange={applySearchState}
      itemsPerPage={ITEM_PER_PAGE_VERIFIED}
      {...searchParams}
    >
      <FilterContextProvider>
        {/* SEO */}
        <GatsbySeoCustom
          title={title}
          titleTemplate={site?.siteMetadata?.titleTemplate ?? ''}
          description={description}
          canonical={canonical}
          language={locale}
          openGraph={{
            type: 'website',
            title,
            description: site?.siteMetadata?.description ?? '',
          }}
        />
        <BreadcrumbJsonLd
          itemListElements={collection?.breadcrumbList.itemListElement ?? []}
        />

        {/*
        Sections: Components imported from '../components/sections' only.
        Do not import or render components from any other folder in here.
      */}

        <div className="page-title__background">
          <div className='"page__section page-title__content'>
            <h1
              className={
                isLifePage ? 'page-title__text life' : 'page-title__text'
              }
            >
              {titlePage || ''}
            </h1>
          </div>
        </div>

        <section className="page__section cms-departament-components">
          <RenderCMS sections={sections} fold="above" />
        </section>
        <ProductGallery
          term={null}
          base={base}
          title={title}
          description={description}
          cmsHome={cmsHome}
          defaultFacets={defaultFacets}
          sendInsiderEvent
        />
      </FilterContextProvider>
    </SearchProvider>
  )
}

/**
 * This query is run during SSG
 * */

export const querySSG = graphql`
  query CollectionPageQuery {
    site {
      siteMetadata {
        titleTemplate
        title
        description
        siteUrl
      }
    }
    cmsHome {
      sections {
        name
        data
      }
    }

    allCmsPlp {
      edges {
        node {
          sections {
            name
            data
          }
        }
      }
    }
    cmsGlobalComponents {
      sections {
        name
        data
      }
    }
  }
`

export const querySSR = gql`
  query ServerCollectionPageQuery($slug: String!) {
    collection(slug: $slug) {
      seo {
        title
        description
      }
      breadcrumbList {
        itemListElement {
          item
          name
          position
        }
      }
      meta {
        selectedFacets {
          key
          value
        }
      }
    }

    cmsHome {
      sections {
        name
        data
      }
    }
  }
`

export const getServerData = async ({
  params: { slug },
}: {
  params: Record<string, string>
}) => {
  const THIRTY_MINUTES_CACHE = `s-maxage=1800, stale-while-revalidate`

  const { data, errors = [] } = await execute({
    operationName: querySSR,
    variables: { slug },
  })

  const notFound = errors.find(isNotFoundError)

  if (data === null || notFound) {
    const params = new URLSearchParams({
      from: encodeURIComponent(`/${slug}`),
    })

    return {
      status: 301,
      props: null,
      headers: {
        'cache-control': THIRTY_MINUTES_CACHE,
        location: `/404/?${params.toString()}}`,
      },
    }
  }

  if (errors && errors?.length > 0) {
    throw new Error(`${errors[0]}`)
  }

  const isCollection =
    data?.collection?.meta?.selectedFacets?.filter(
      (e: { key: string }) => e.key === 'productclusterids'
    ).length > 0

  if (isCollection) {
    const collectionId = data?.collection?.meta?.selectedFacets?.filter(
      (e: { key: string }) => e.key === 'productclusterids'
    )?.[0]?.value

    const query = `query {
      collection (id: "${collectionId}") @context(provider: "vtex.catalog-graphql") {
        id
        name
        status
        searchable
        description
      }
    }`

    const { secureAccount } = storeConfig
    const { data: collectionDatail }: { data: any } = await axios.post(
      `https://${secureAccount}.com.br/_v/private/graphql/v1`,
      {
        query,
      },
      {
        headers: {
          Accept: 'application/vnd.vtex.ds.v10+json',
          'Content-Type': 'application/json',
        },
      }
    )

    if (collectionDatail?.data?.collection?.searchable) {
      return {
        status: 301,
        props: null,
        headers: {
          'cache-control': THIRTY_MINUTES_CACHE,
          location: `/colecao/${slug}`,
        },
      }
    }

    return {
      status: 301,
      props: null,
      headers: {
        'cache-control': THIRTY_MINUTES_CACHE,
        location: `/`,
      },
    }
  }

  return {
    status: 200,
    props: data ?? {},
    headers: {
      'cache-control': THIRTY_MINUTES_CACHE,
    },
  }
}

Page.displayName = 'Page'
export default mark(Page)
